import { FC } from "react";
import { HiShoppingCart, HiUser } from "react-icons/hi";
import { ActionButton, Button, SearchInput } from "./ui";
import { Link } from "react-router-dom";
import logo from "assets/images/logo.svg";

const links = [
  { id: 1, name: "صفحه اصلی", path: "/", isActive: true },
  { id: 2, name: "کیف", path: "#", isActive: false },
  { id: 3, name: "کفش", path: "#", isActive: false },
  { id: 4, name: "ساعت", path: "#", isActive: false },
  { id: 5, name: "بدلیجات", path: "#", isActive: false },
];

export const Header: FC = () => {
  return (
    <header className="bg-secondary-dark">
      <div className="container pb-5 px-5 pt-8 mx-auto">
        <div className="flex items-center justify-between space-x-4 lg:space-x-10">
          <div className="flex lg:w-0 lg:flex-1">
            <img src={logo} className="w-14" />
            <div className="mr-6 w-96 hidden lg:block">
              <SearchInput placeholder="جستجو در اکسیژن … " />
            </div>
          </div>

          <div className="items-center justify-end flex-1 hidden space-x-4 space-x-reverse sm:flex">
            <Button variant="outline" icon={<HiUser />}>
              ورود به حساب کاربری
            </Button>

            <ActionButton>
              <HiShoppingCart />
            </ActionButton>
          </div>

          <div className="lg:hidden">
            <button className="text-gray-100 text-2xl" type="button">
              <svg
                aria-hidden="true"
                className="w-8 h-8"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 6h16M4 12h16M4 18h16"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className="container p-5 mx-auto hidden md:block">
        <ul className="flex items-center space-x-12 space-x-reverse text-lg">
          {links.map((link) => (
            <li
              key={link.id}
              className={`pb-4 ${
                link.isActive ? "border-b-2 border-primary text-primary" : ""
              }`}
            >
              <Link to={link.path}>{link.name}</Link>
            </li>
          ))}
        </ul>
      </div>
    </header>
  );
};
