import { useEffect } from "react";

import NProgress from "nprogress";
import "styles/nprogress.css";

export const Loading = () => {
  // config
  NProgress.configure({ showSpinner: false });

  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  });
  return <></>;
};
