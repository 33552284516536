import { FC, HTMLAttributes, ReactNode } from "react";
import { Spinner } from ".";
import clsx from "classnames";

interface IActionButton extends HTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  full?: boolean;
  icon?: ReactNode;
  variant?: string;
}

export const ActionButton: FC<IActionButton> = ({
  children,
  className,
  loading,
  ...props
}) => {
  const base = `flex items-center justify-center bg-transparent border border-primary text-primary p-3 rounded-xl font-extrabold transform active:scale-[0.95] transition duration-200 ${
    loading ? "pointer-events-none opacity-80" : ""
  } min-h-[48px] min-w-[48px]`;

  return (
    <button className={clsx(base, className)} {...props}>
      {loading ? <Spinner /> : children}
    </button>
  );
};
