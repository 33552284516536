import React from "react";
import { FC } from "react";
import { HTMLAttributes } from "react";
import { HiSearch } from "react-icons/hi";

interface ISearchInput extends HTMLAttributes<HTMLInputElement> {}

export const SearchInput: FC<ISearchInput> = ({ placeholder, ...props }) => {
  return (
    <div className="relative flex items-center py-2 px-5 space-x-2 space-x-reverse text-[#C4CCC9] bg-secondary-light rounded-xl min-h-[48px]">
      <HiSearch className="text-xl" />
      <input
        type="text"
        placeholder={placeholder}
        className="bg-transparent w-full border-none focus:outline-none placeholder:text-[#C4CCC9]"
        {...props}
      />
    </div>
  );
};
