import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const NotFound = lazy(() => import("pages/NotFound"));
const Home = lazy(() => import("pages/Home"));

const routes: RouteObject[] = [
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: "/",
    element: <Home />,
  },
];

export default routes;
