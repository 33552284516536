import { FC, HTMLAttributes, ReactNode } from "react";
import { Spinner } from ".";
import clsx from "classnames";

interface IButton extends HTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  full?: boolean;
  icon?: ReactNode;
  variant?: string;
}

export const Button: FC<IButton> = ({
  children,
  className,
  loading,
  full,
  icon,
  variant = "primary",
  ...props
}) => {
  const base = `flex items-center justify-center py-3 px-6 rounded-xl font-semibold transform active:scale-[0.95] transition duration-200 ${
    loading ? "pointer-events-none opacity-80" : ""
  } min-h-[48px] ${full ? "w-full" : "min-w-[160px]"}`;

  const variants: any = {
    primary: "bg-primary shadow-xl shadow-primary/10 text-white",
    light: "bg-white text-primary",
    outline: "bg-transparent border border-primary text-primary",
  };

  return (
    <button className={clsx(base, variants[variant], className)} {...props}>
      {loading ? (
        <Spinner />
      ) : (
        <div className="flex items-center space-x-3 space-x-reverse">
          {icon && icon}
          <span>{children}</span>
        </div>
      )}
    </button>
  );
};
