import { FC } from "react";
import { FaInstagram, FaFacebookF, FaTwitter, FaYoutube } from "react-icons/fa";
import enamadImg from "assets/images/enamad.png";
import zarinpalImg from "assets/images/zarinpal.png";

export const Footer: FC = () => {
  return (
    <footer className="bg-secondary-dark">
      <div className="container px-5 py-14 mx-auto">
        <div className="md:grid grid-cols-12 gap-10 border-b border-secondary-light pb-10 md:pb-20 border-dashed">
          <div className="col-span-12 md:col-span-6 space-y-8">
            <h1 className="text-xl">فروشگاه اینترنتی اکسیژن</h1>
            <p className="md:w-[90%]">
              اکسیژن یک مرکز خرید و فروش آنلاین کالاست که خدمات خود را به صورت
              رایگان (بدون کارمزد) ارائه می نماید. هم اکنون بیش از 10,000
              فروشگاه از سراسر ایران در حال فعالیت در اکسیژن می باشند.
            </p>
            <div className="flex items-center space-x-5 space-x-reverse text-lg">
              <FaInstagram className="hover:text-primary transition-colors duration-300 cursor-pointer" />
              <FaFacebookF className="hover:text-primary transition-colors duration-300 cursor-pointer" />
              <FaTwitter className="hover:text-primary transition-colors duration-300 cursor-pointer" />
              <FaYoutube className="hover:text-primary transition-colors duration-300 cursor-pointer" />
            </div>
          </div>

          <div className="col-span-2 hidden md:block">
            <h2 className="text-xl">خدمات مشتریان</h2>
            <ul className="opacity-75 space-y-5 mt-10">
              <li>
                <a href="#">شرایط بازگشت محصول</a>
              </li>
              <li>
                <a href="#">حریم خصوصی</a>
              </li>
              <li>
                <a href="#">راهنمای خرید</a>
              </li>
            </ul>
          </div>
          <div className="col-span-2 hidden md:block">
            <h2 className="text-xl">راهنما</h2>
            <ul className="opacity-75 space-y-5 mt-10">
              <li>
                <a href="#">شرایط بازگشت محصول</a>
              </li>
              <li>
                <a href="#">حریم خصوصی</a>
              </li>
              <li>
                <a href="#">راهنمای خرید</a>
              </li>
            </ul>
          </div>
          <div className="hidden md:flex col-span-2 flex-col items-end space-y-10">
            <img src={zarinpalImg} />
            <img src={enamadImg} />
          </div>
        </div>
        <p className="mt-10 text-center mb-0">
          کليه حقوق اين سايت متعلق به شرکت نبوغ دیجیتال ( فروشگاه اینترنتی
          اکسیژن ) می‌باشد.
        </p>
      </div>
    </footer>
  );
};
