import { FC, Suspense } from "react";
import { useRoutes } from "react-router-dom";
import { Loading, Layout } from "components";
import routes from "config/routes";

const App: FC = () => {
  const content = useRoutes(routes);

  return (
    <Suspense fallback={<Loading />}>
      <Layout>{content}</Layout>
    </Suspense>
  );
};

export default App;
