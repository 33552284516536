import { FC, ReactNode } from "react";
import { Header, Footer } from "components";

interface ILayout {
  children: ReactNode;
}

export const Layout: FC<ILayout> = ({ children }) => {
  return (
    <div className="h-full w-full flex flex-col space-y-16">
      <Header />
      <main className="grow">{children}</main>
      <Footer />
    </div>
  );
};
